export const isEmpty = (obj) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const isEmptyObj = (obj) => {
  for (const key in obj) {
    if (
      obj[key] !== null &&
      obj[key] !== "" &&
      obj[key] !== false &&
      obj[key] !== undefined
    )
      return false;
  }
  return true;
};

export const allowOnlyIntegers = (evt) => {
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.preventDefault();
  }
};

export const roundOff = (value, isOneDecimalPlace = false) => {
  if (isOneDecimalPlace) {
    return Math.round((value + Number.EPSILON) * 10) / 10;
  } else {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }
};

export const random = (array) => {
  return array[Math.floor(Math.random() * array.length)];
};

export const padDecimalPlaces = (value = 0, pad = 2) => {
  return (Math.round(value * 100) / 100).toFixed(pad);
};
