import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import {
  accountDataReducer,
  initialState as accountData,
} from "./reducers/accountDataReducer";
import { AccountDataProvider } from "./context/accountData";

import {
  userIpInfoReducer,
  initialState as userIpInfo,
} from "./reducers/userIpInfoReducer";
import { UserIpInfoProvider } from "./context/userIpInfo";

import "./styles/style.scss";

ReactDOM.render(
  <AccountDataProvider initialState={accountData} reducer={accountDataReducer}>
    <UserIpInfoProvider initialState={userIpInfo} reducer={userIpInfoReducer}>
      <App />
    </UserIpInfoProvider>
  </AccountDataProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
