/* eslint-disable react-hooks/exhaustive-deps */
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { Switch, BrowserRouter } from "react-router-dom";

import { isEmpty } from "_dash";
import { getIpInfo, getUserDetail } from "actions";
import { LOGGED_IN_ROUTES, NON_LOGIN_ROUTES } from "routes";

import instance from "./axios";
import AccountHook from "hooks/account";
import UserIpInfoHook from "hooks/userIpInfo";
import RouteWithSubRoutes from "./components/routeWithSubRoutes";

const App = ({ accountData, setAccountData, setUserIpInfo }) => {
  const isLoggedIn = !isEmpty(accountData);

  const [loading, setLoading] = useState(true);
  const [appRoutes, setAppRoutes] = useState([]);

  useEffect(() => {
    getUserDetail()
      .then((response) => {
        setAccountData(response);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getIpInfo().then((response) => {
      setUserIpInfo(response);
      instance.defaults.headers.common["ip"] = response.ip;
    });
  }, []);

  useEffect(() => {
    if (!loading) {
      if (isLoggedIn) {
        setAppRoutes(LOGGED_IN_ROUTES);
      } else {
        setAppRoutes(NON_LOGIN_ROUTES);
      }
    }
  }, [isLoggedIn, loading]);

  if (loading) {
    return (
      <div className="app-loader">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        {appRoutes.map((route, i) => {
          return <RouteWithSubRoutes key={i} {...route} />;
        })}
      </Switch>
    </BrowserRouter>
  );
};

export default AccountHook(UserIpInfoHook(App));
