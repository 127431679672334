import React from "react";

import {
  TeamOutlined,
  UserOutlined,
  FileOutlined,
  SettingOutlined,
  BulbOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import NotFoundPage from "pages/404Page";
import RenderRoutes from "components/renderRoutes";

const WebsiteLayoutComponent = React.lazy(() =>
  import("layouts/websiteLayout")
);
const DashboardLayoutComponent = React.lazy(() => import("layouts/appLayout"));

const WebsiteHomeComponent = React.lazy(() => import("pages/website"));
const WebsiteTermsComponent = React.lazy(() => import("pages/website/terms"));
const WebsitePricingComponent = React.lazy(() =>
  import("pages/website/pricing")
);
const WebsiteFeaturesComponent = React.lazy(() =>
  import("pages/website/features")
);
const WebsiteTemplatesComponent = React.lazy(() =>
  import("pages/website/templates")
);
const WebsitePrivacyPolicyComponent = React.lazy(() =>
  import("pages/website/privacyPolicy")
);

const UserResetPasswordComponent = React.lazy(() =>
  import("pages/userResetPassword")
);
const UserEmailVerificationComponent = React.lazy(() =>
  import("pages/userEmailVerification")
);

const CustomerManagementComponent = React.lazy(() =>
  import("pages/customerManagement")
);
const ReceiptsManagementComponent = React.lazy(() =>
  import("pages/receiptManagement")
);
const ReceiptCreateOrEditComponent = React.lazy(() =>
  import("pages/receiptManagement/createOrEdit")
);
const CustomerReceiptComponent = React.lazy(() =>
  import("pages/customerManagement/receipts")
);

const FaqManagementComponent = React.lazy(() => import("pages/faqManagement"));

const UserAccountSettingsComponent = React.lazy(() =>
  import("pages/accountManagement/userSettings")
);
const UserSubscriptionComponent = React.lazy(() =>
  import("pages/accountManagement/userSubscription")
);
const TaxSettingComponent = React.lazy(() =>
  import("pages/settingsManagement/taxes")
);
const EmailTemplateSettingsComponent = React.lazy(() =>
  import("pages/settingsManagement/emailTemplate")
);
const CompanySettingsComponent = React.lazy(() =>
  import("pages/settingsManagement/company")
);
const ReceiptSettingsComponent = React.lazy(() =>
  import("pages/settingsManagement/receipt")
);

export const INDEX_ROUTE = "/";
export const FAQS_ROUTE = "/faqs/";
export const UPGRADE_ROUTE = "/upgrade/";
export const RECEIPTS_ROUTE = "/receipts/";
export const USER_SETTINGS_ROUTE = "/user-settings/";
export const USER_SUBSCRIPTION_ROUTE = "/user-subscription/";

export const USER_VERIFICATION_ROUTE = "/verification/";
export const USER_RESET_PASSWORD_ROUTE = "/reset-password/";

export const RECEIPT_CREATE_ROUTE = `${RECEIPTS_ROUTE}create/`;
export const RECEIPT_EDIT_ROUTE = `${RECEIPTS_ROUTE}:receiptId/`;

export const CUSTOMER_RECEIPT_ROUTE = "/customers/:customerId/receipts/";

export const TAX_SETTING_ROUTE = "/tax-settings/";
export const COMPANY_SETTING_ROUTE = "/company-settings/";
export const RECEIPT_SETTING_ROUTE = "/receipt-settings/";
export const EMAIL_TEMPLATE_SETTING_ROUTE = "/email-template-settings/";

export const WEBSITE_TERMS_ROUTE = "/terms/";
export const WEBSITE_PRICING_ROUTE = "/pricing/";
export const WEBSITE_FEATURES_ROUTE = "/features/";
export const WEBSITE_TEMPLATES_ROUTE = "/templates/";
export const WEBSITE_PRIVACY_POLICY_ROUTE = "/privacy-policy/";

export const getCustomerReceiptRoute = (customerId) => {
  return CUSTOMER_RECEIPT_ROUTE.replace(":customerId", customerId);
};

export const getReceiptEditRoute = (receiptId) => {
  return RECEIPT_EDIT_ROUTE.replace(":receiptId", receiptId);
};

export const NON_LOGIN_ROUTES = [
  {
    path: "/",
    name: "index",
    component: RenderRoutes,
    routes: [
      {
        exact: true,
        name: "user-reset-password",
        path: USER_RESET_PASSWORD_ROUTE,
        component: UserResetPasswordComponent,
      },
      {
        exact: true,
        name: "user-email-verification",
        path: USER_VERIFICATION_ROUTE,
        component: UserEmailVerificationComponent,
      },
      {
        path: "/",
        name: "website-layout",
        component: WebsiteLayoutComponent,
        routes: [
          {
            exact: true,
            name: "homepage",
            path: INDEX_ROUTE,
            component: WebsiteHomeComponent,
          },
          {
            exact: true,
            name: "pricing",
            path: WEBSITE_PRICING_ROUTE,
            component: WebsitePricingComponent,
          },
          {
            exact: true,
            name: "features",
            path: WEBSITE_FEATURES_ROUTE,
            component: WebsiteFeaturesComponent,
          },
          {
            exact: true,
            name: "templates",
            path: WEBSITE_TEMPLATES_ROUTE,
            component: WebsiteTemplatesComponent,
          },
          {
            exact: true,
            name: "privacy-policy",
            path: WEBSITE_PRIVACY_POLICY_ROUTE,
            component: WebsitePrivacyPolicyComponent,
          },
          {
            exact: true,
            name: "terms",
            path: WEBSITE_TERMS_ROUTE,
            component: WebsiteTermsComponent,
          },
        ],
      },
    ],
  },
];

export const LOGGED_IN_ROUTES = [
  {
    path: "/",
    name: "index",
    component: RenderRoutes,
    routes: [
      {
        exact: true,
        name: "user-email-verification",
        path: USER_VERIFICATION_ROUTE,
        component: UserEmailVerificationComponent,
      },
      {
        path: "/",
        name: "layout",
        component: DashboardLayoutComponent,
        routes: [
          {
            exact: true,
            name: "customers",
            path: INDEX_ROUTE,
            component: CustomerManagementComponent,
          },
          {
            exact: true,
            name: "receipts",
            path: RECEIPTS_ROUTE,
            component: ReceiptsManagementComponent,
          },
          {
            exact: true,
            name: "receipt-create",
            path: RECEIPT_CREATE_ROUTE,
            component: ReceiptCreateOrEditComponent,
          },
          {
            exact: true,
            name: "receipt-edit",
            path: RECEIPT_EDIT_ROUTE,
            component: ReceiptCreateOrEditComponent,
          },
          {
            exact: true,
            name: "customer-receipts",
            path: CUSTOMER_RECEIPT_ROUTE,
            component: CustomerReceiptComponent,
          },
          {
            exact: true,
            name: "user-settings",
            path: USER_SETTINGS_ROUTE,
            component: UserAccountSettingsComponent,
          },
          {
            exact: true,
            name: "user-subscription",
            path: USER_SUBSCRIPTION_ROUTE,
            component: UserSubscriptionComponent,
          },
          {
            exact: true,
            name: "tax-settings",
            path: TAX_SETTING_ROUTE,
            component: TaxSettingComponent,
          },
          {
            exact: true,
            name: "email-template-settings",
            path: EMAIL_TEMPLATE_SETTING_ROUTE,
            component: EmailTemplateSettingsComponent,
          },
          {
            exact: true,
            name: "company-settings",
            path: COMPANY_SETTING_ROUTE,
            component: CompanySettingsComponent,
          },
          {
            exact: true,
            name: "receipt-settings",
            path: RECEIPT_SETTING_ROUTE,
            component: ReceiptSettingsComponent,
          },
          {
            exact: true,
            name: "faqs",
            path: FAQS_ROUTE,
            component: FaqManagementComponent,
          },
        ],
      },
    ],
  },
  {
    component: NotFoundPage,
  },
];

export const PARENT_SIDEBAR_ITEMS = [
  {
    key: "customers",
    label: "Customers",
    icon: <TeamOutlined />,
    route: INDEX_ROUTE,
  },
  {
    key: "receipts",
    label: "Receipts",
    icon: <FileOutlined />,
    route: RECEIPTS_ROUTE,
  },
  {
    is_hidden: true,
    key: "receipts",
    route: RECEIPT_CREATE_ROUTE,
  },
  {
    is_hidden: true,
    key: "receipts",
    route: RECEIPT_EDIT_ROUTE,
  },
  {
    key: "account",
    label: "Account",
    icon: <UserOutlined />,
    submenu: [
      {
        key: "user-information",
        label: "User information",
        route: USER_SETTINGS_ROUTE,
      },
      {
        key: "subscription",
        label: "Subscription",
        route: USER_SUBSCRIPTION_ROUTE,
      },
    ],
  },
  {
    key: "settings",
    label: "Settings",
    icon: <SettingOutlined />,
    submenu: [
      {
        key: "my-company",
        label: "My company",
        route: COMPANY_SETTING_ROUTE,
      },
      {
        key: "receipt-settings",
        label: "Receipt settings",
        route: RECEIPT_SETTING_ROUTE,
      },
      {
        key: "email-templates",
        label: "Email templates",
        route: EMAIL_TEMPLATE_SETTING_ROUTE,
      },
      {
        key: "taxes",
        label: "Taxes",
        route: TAX_SETTING_ROUTE,
      },
    ],
  },
];

export const FOOTER_SIDEBAR_ITEMS = [
  {
    key: "feedback",
    label: "Give us feedback",
    url: "https://receiptmaker.feedbackchimp.space/",
    icon: <BulbOutlined />,
  },
  {
    key: "faqs",
    label: "FAQs",
    route: FAQS_ROUTE,
    icon: <ExclamationCircleOutlined />,
  },
];

export const ALL_SIDEBAR_ITEMS = [
  ...PARENT_SIDEBAR_ITEMS,
  ...FOOTER_SIDEBAR_ITEMS,
];

export const WEBSITE_MENU_ITEMS = [
  {
    key: "home",
    is_hidden: true,
    route: INDEX_ROUTE,
    faq_lookup_key: "show_on_homepage",
  },
  {
    key: "features",
    label: "Features",
    route: WEBSITE_FEATURES_ROUTE,
    faq_lookup_key: "show_on_features",
  },
  {
    key: "pricing",
    label: "Pricing",
    route: WEBSITE_PRICING_ROUTE,
    faq_lookup_key: "show_on_pricing",
  },
];
