import React from "react";

import { UserIpInfoConsumer } from "../context/userIpInfo";
import { setUserIpInfo as userIpInfoSetter } from "../reducers/userIpInfoReducer";

const UserIpInfoHook = (BaseComponent) => (props) => {
  const [consumer, dispatch] = UserIpInfoConsumer();

  function setUserIpInfo(data) {
    dispatch(userIpInfoSetter(data));
  }

  return (
    <BaseComponent
      {...props}
      userIpInfo={consumer.detail}
      setUserIpInfo={(data) => setUserIpInfo(data)}
    />
  );
};

export default UserIpInfoHook;
