export const LOGIN_API_PATH = "login/";
export const REGISTER_API_PATH = "register/";
export const USER_RESET_PASSWORD_API_PATH = "reset_password/";
export const USER_EMAIL_VERIFICATION_API_PATH = "verify_email/";
export const USER_PASSWORD_RESET_REQUEST_API_PATH = "request_reset_password/";
export const CHECK_USER_EMAIL_EXISTS_API_PATH =
  "register/is_already_registered/";

export const USER_BASE_API_PATH = "user/";
export const USER_PASSWORD_UPDATE_API_PATH = `${USER_BASE_API_PATH}update_password/`;
export const USER_COMPANY_LOGO_UPLOAD_API_PATH = `${USER_BASE_API_PATH}upload_company_logo/`;

export const FAQ_LIST_API_PATH = "faqs/";
export const SUBSCRIPTION_PLAN_API_PATH = "plans/";
export const CURRENCY_LIST_API_PATH = "currencies/";
export const RECEIPT_TEMPLATE_LIST_API_PATH = "receipt-templates/";
export const RECEIPT_TEMPLATE_HTML_API_PATH = `${RECEIPT_TEMPLATE_LIST_API_PATH}{}/get_html/`;

export const CUSTOMER_API_PATH = "customers/";
export const CUSTOMER_DETAIL_API_PATH = `${CUSTOMER_API_PATH}{}/`;

export const RECEIPT_TAX_API_PATH = "receipt_taxes/";
export const RECEIPT_TAX_DETAIL_API_PATH = `${RECEIPT_TAX_API_PATH}{}/`;

export const RECEIPT_API_PATH = "receipts/";
export const RECEIPT_DETAIL_API_PATH = `${RECEIPT_API_PATH}{}/`;
export const RECEIPT_COUNT_API_PATH = `${RECEIPT_API_PATH}count/`;
export const RECEIPT_SEND_API_PATH = `${RECEIPT_DETAIL_API_PATH}send/`;
export const RECEIPT_DOWNLOAD_API_PATH = `${RECEIPT_DETAIL_API_PATH}download/`;
export const RECEIPT_LOGO_UPLOAD_API_PATH = `${RECEIPT_API_PATH}upload_logo/`;

export const ANONYMOUS_RECEIPT_API_PATH = "anonymous_receipt/";
export const ANONYMOUS_RECEIPT_DETAIL_API_PATH = `${ANONYMOUS_RECEIPT_API_PATH}{}/`;
export const SAVE_ANONYMOUS_RECEIPT_API_PATH = `${ANONYMOUS_RECEIPT_API_PATH}save/`;
export const ANONYMOUS_RECEIPT_LOGO_UPLOAD_API_PATH = `${ANONYMOUS_RECEIPT_API_PATH}upload_logo/`;
export const ANONYMOUS_RECEIPT_DOWNLOAD_API_PATH = `${ANONYMOUS_RECEIPT_DETAIL_API_PATH}download/`;

export const RECEIPT_DEFAULT_SETTING_API_PATH = `receipt_settings/`;
export const RECEIPT_DEFAULT_SETTING_UPLOAD_LOGO_API_PATH = `${RECEIPT_DEFAULT_SETTING_API_PATH}upload_logo/`;

export const RECEIPT_EMAIL_TEMPLATE_API_PATH = `receipt_email_template/`;

export const SUBSCRIPTION_API_PATH = "payments/subscription/";
