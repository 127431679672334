export const initialState = {};

const SET_USER_IP_INFO = "SET_USER_IP_INFO";

export const setUserIpInfo = (data) => {
  return {
    payload: data,
    type: SET_USER_IP_INFO,
  };
};

export const userIpInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_IP_INFO:
      return {
        detail: action.payload,
      };
    default:
      return { detail: state };
  }
};

export default userIpInfoReducer;
