/*eslint-disable*/
import React, { useReducer, useContext, createContext } from "react";

const UserIpInfoContext = createContext();

export const UserIpInfoProvider = ({ reducer, initialState, children }) => {
  const contextValue = useReducer(reducer, initialState);
  return (
    <UserIpInfoContext.Provider value={contextValue}>
      {children}
    </UserIpInfoContext.Provider>
  );
};

export const UserIpInfoConsumer = () => {
  const contextValue = useContext(UserIpInfoContext);
  return contextValue;
};
