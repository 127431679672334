import axios from "axios";
import instance from "../axios";
import * as endpoints from "./endpoints";
import { AUTHORIZATION_KEY } from "global_constants";

export const getCompanyLogoUploadPath = () => {
  return `${instance.defaults.baseURL}${endpoints.USER_COMPANY_LOGO_UPLOAD_API_PATH}`;
};

export const getReceiptLogoUploadPath = () => {
  return `${instance.defaults.baseURL}${endpoints.RECEIPT_LOGO_UPLOAD_API_PATH}`;
};

export const getAnonymousReceiptLogoUploadPath = () => {
  return `${instance.defaults.baseURL}${endpoints.ANONYMOUS_RECEIPT_LOGO_UPLOAD_API_PATH}`;
};

export const getIpInfo = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loginUser = (payload) => {
  delete instance.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.LOGIN_API_PATH, payload)
      .then((response) => {
        const { data } = response;
        instance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.token}`;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const registerUser = (payload) => {
  delete instance.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.REGISTER_API_PATH, payload)
      .then((response) => {
        const { data } = response;
        instance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.token}`;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserDetail = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem(AUTHORIZATION_KEY);
    if (token) {
      instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      instance
        .get(endpoints.USER_BASE_API_PATH)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      reject();
    }
  });
};

export const updateUserDetail = (payload, userId) => {
  let path = endpoints.USER_BASE_API_PATH;
  path = path.concat(userId, "/");
  return instance.patch(path, payload);
};

export const updateUserPassword = (payload) => {
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.USER_PASSWORD_UPDATE_API_PATH, payload)
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const checkUserEmailExists = (payload) => {
  return instance.post(endpoints.CHECK_USER_EMAIL_EXISTS_API_PATH, payload);
};

export const requestResetPassword = (payload) => {
  return instance.post(endpoints.USER_PASSWORD_RESET_REQUEST_API_PATH, payload);
};

export const resetUserPassword = (payload) => {
  return instance.post(endpoints.USER_RESET_PASSWORD_API_PATH, payload);
};

export const verifyUserEmail = (payload) => {
  return instance.post(endpoints.USER_EMAIL_VERIFICATION_API_PATH, payload);
};

export const updateSubscription = (payload) => {
  return instance.patch(endpoints.SUBSCRIPTION_API_PATH, payload);
};

export const cancelOrPauseSubscription = (isPaused = false) => {
  let path = endpoints.SUBSCRIPTION_API_PATH;
  return instance.delete(path.concat("?is_paused=", isPaused));
};

export const getFaqs = () => {
  return instance.get(endpoints.FAQ_LIST_API_PATH, {
    transformRequest: (data, headers) => {
      delete headers.common["Authorization"];
      return data;
    },
  });
};

export const getReceiptTemplates = () => {
  return instance.get(endpoints.RECEIPT_TEMPLATE_LIST_API_PATH, {
    transformRequest: (data, headers) => {
      delete headers.common["Authorization"];
      return data;
    },
  });
};

export const getReceiptTemplateHtml = (
  payload,
  templateId,
  useDefault = false
) => {
  return instance.post(
    endpoints.RECEIPT_TEMPLATE_HTML_API_PATH.replace("{}", templateId).concat(
      "?use_default=",
      useDefault
    ),
    payload
  );
};

export const getCustomers = (queryString) => {
  return instance.get(
    endpoints.CUSTOMER_API_PATH.concat("?", queryString || "")
  );
};

export const getCustomerDetail = (customerId) => {
  return instance.get(
    endpoints.CUSTOMER_DETAIL_API_PATH.replace("{}", customerId)
  );
};

export const createCustomer = (payload) => {
  return instance.post(endpoints.CUSTOMER_API_PATH, payload);
};

export const editCustomer = (payload, customerId) => {
  return instance.patch(
    endpoints.CUSTOMER_DETAIL_API_PATH.replace("{}", customerId),
    payload
  );
};

export const deleteCustomer = (customerId) => {
  return instance.delete(
    endpoints.CUSTOMER_DETAIL_API_PATH.replace("{}", customerId)
  );
};

export const getReceiptTaxes = (queryString) => {
  return instance.get(
    endpoints.RECEIPT_TAX_API_PATH.concat("?", queryString || "")
  );
};

export const createReceiptTax = (payload) => {
  return instance.post(endpoints.RECEIPT_TAX_API_PATH, payload);
};

export const editReceiptTax = (payload, customerId) => {
  return instance.patch(
    endpoints.RECEIPT_TAX_DETAIL_API_PATH.replace("{}", customerId),
    payload
  );
};

export const deleteReceiptTax = (customerId) => {
  return instance.delete(
    endpoints.RECEIPT_TAX_DETAIL_API_PATH.replace("{}", customerId)
  );
};

export const getAnonymousReceipt = (receiptId) => {
  return instance.get(
    endpoints.ANONYMOUS_RECEIPT_DETAIL_API_PATH.replace("{}", receiptId)
  );
};

export const saveAnonymousReceipt = (payload) => {
  return instance.post(endpoints.SAVE_ANONYMOUS_RECEIPT_API_PATH, payload);
};

export const downloadAnonymousReceipt = (receiptId) => {
  return instance.get(
    endpoints.ANONYMOUS_RECEIPT_DOWNLOAD_API_PATH.replace("{}", receiptId),
    {
      timeout: 12000,
      responseType: "arraybuffer",
    }
  );
};

export const getReceipts = (queryString) => {
  return instance.get(
    endpoints.RECEIPT_API_PATH.concat("?", queryString || "")
  );
};

export const getReceiptCount = () => {
  return instance.get(endpoints.RECEIPT_COUNT_API_PATH);
};

export const getReceiptDetail = (receiptId) => {
  return instance.get(
    endpoints.RECEIPT_DETAIL_API_PATH.replace("{}", receiptId)
  );
};

export const createReceipt = (payload) => {
  return instance.post(endpoints.RECEIPT_API_PATH, payload);
};

export const updateReceipt = (payload, receiptId) => {
  return instance.patch(
    endpoints.RECEIPT_DETAIL_API_PATH.replace("{}", receiptId),
    payload
  );
};

export const downloadReceipt = (receiptId) => {
  return instance.post(
    endpoints.RECEIPT_DOWNLOAD_API_PATH.replace("{}", receiptId),
    {},
    {
      responseType: "arraybuffer",
    }
  );
};

export const sendReceipt = (payload, receiptId) => {
  return instance.post(
    endpoints.RECEIPT_SEND_API_PATH.replace("{}", receiptId),
    payload
  );
};

export const deleteReceipt = (receiptId) => {
  return instance.delete(
    endpoints.RECEIPT_DETAIL_API_PATH.replace("{}", receiptId)
  );
};

export const getPlans = () => {
  return instance.get(endpoints.SUBSCRIPTION_PLAN_API_PATH, {
    transformRequest: (data, headers) => {
      delete headers.common["Authorization"];
      return data;
    },
  });
};

export const getCurrencies = () => {
  return instance.get(endpoints.CURRENCY_LIST_API_PATH, {
    transformRequest: (data, headers) => {
      delete headers.common["Authorization"];
      return data;
    },
  });
};

export const getReceiptEmailTemplate = () => {
  return instance.get(endpoints.RECEIPT_EMAIL_TEMPLATE_API_PATH);
};

export const saveReceiptEmailTemplate = (payload) => {
  return instance.post(endpoints.RECEIPT_EMAIL_TEMPLATE_API_PATH, payload);
};

export const getReceiptDefaultSettings = () => {
  return instance.get(endpoints.RECEIPT_DEFAULT_SETTING_API_PATH);
};

export const saveReceiptDefaultSettings = (payload) => {
  return instance.post(endpoints.RECEIPT_DEFAULT_SETTING_API_PATH, payload);
};
